
    import { Options, Vue } from "vue-property-decorator";
    import * as api from "@/api/projectplan";

    @Options({})
    export default class extends Vue {
        private progressDetail: any = {};

        approveStatusFilter(val: any) {
            if (val === 0) {
                return "未发起";
            } else if (val === 1) {
                return "已发起";
            } else if (val === 2) {
                return "已通过";
            } else if (val === 3) {
                return "未通过";
            }
        }

        mounted() {
            api.taskDetail(this.$route.query.id).then((res: any) => {
                if (res.code == 0) {
                    this.progressDetail = res.data;
                } else {
                    this.$toast.fail(res.msg);
                }
            });
        }
    }
